import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withKeys as _withKeys, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "table" }
const _hoisted_2 = { colspan: "4" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = { class: "txt" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["onUpdate:modelValue", "onKeyup", "onBlur"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "action" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { colspan: "4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          _createElementVNode("th", _hoisted_2, [
            _createElementVNode("form", {
              onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addTask && _ctx.addTask(...args)), ["prevent"]))
            }, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newTask) = $event)),
                placeholder: _ctx.$t('extra.todo.add-task')
              }, null, 8, _hoisted_3), [
                [_vModelText, _ctx.newTask]
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("button", { class: "add-task" }, [
                _createElementVNode("i", { class: "fas fa-plus-circle" })
              ], -1))
            ], 32)
          ])
        ]),
        _createElementVNode("tr", null, [
          _cache[5] || (_cache[5] = _createElementVNode("th", null, "№", -1)),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('extra.todo.task')), 1),
          _createElementVNode("th", null, [
            _cache[4] || (_cache[4] = _createElementVNode("span", { class: "icon" }, [
              _createElementVNode("i", { class: "fas fa-check-circle" })
            ], -1)),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('extra.todo.status')), 1)
          ]),
          _createElementVNode("th", null, _toDisplayString(_ctx.$t('extra.todo.actions')), 1)
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasks, (task, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: task.id
          }, [
            _createElementVNode("td", null, _toDisplayString(index + 1), 1),
            _createElementVNode("td", _hoisted_5, [
              (task.editing)
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      "onUpdate:modelValue": ($event: any) => ((task.name) = $event),
                      onKeyup: _withKeys(($event: any) => (_ctx.editTask(task)), ["enter"]),
                      onBlur: ($event: any) => (_ctx.editTask(task))
                    }, null, 40, _hoisted_7), [
                      [_vModelText, task.name]
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass({ completed: task.completed })
                  }, _toDisplayString(task.name), 3))
            ]),
            _createElementVNode("td", null, [
              _createElementVNode("button", {
                class: "btn",
                onClick: ($event: any) => (_ctx.toggleCompleted(task)),
                title: "Status..."
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(task.completed ? 'fas fa-check' : 'fas fa-clock')
                }, null, 2)
              ], 8, _hoisted_8)
            ]),
            _createElementVNode("td", _hoisted_9, [
              (!task.editing)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn norm txt",
                    onClick: ($event: any) => (task.editing = true)
                  }, _toDisplayString(_ctx.$t('extra.todo.edit')), 9, _hoisted_10))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "btn danger txt",
                onClick: ($event: any) => (_ctx.deleteTask(index))
              }, _toDisplayString(_ctx.$t('extra.todo.delete')), 9, _hoisted_11),
              (!task.editing)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "btn norm icon",
                    onClick: ($event: any) => (task.editing = true),
                    title: "Edit..."
                  }, _cache[6] || (_cache[6] = [
                    _createElementVNode("i", { class: "fas fa-edit" }, null, -1)
                  ]), 8, _hoisted_12))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "btn danger icon",
                onClick: ($event: any) => (_ctx.deleteTask(index)),
                title: "Delete..."
              }, _cache[7] || (_cache[7] = [
                _createElementVNode("i", { class: "fas fa-trash" }, null, -1)
              ]), 8, _hoisted_13)
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("tr", null, [
        _createElementVNode("th", _hoisted_14, [
          _createElementVNode("button", {
            class: "btn danger",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.deleteAllTasks && _ctx.deleteAllTasks(...args)))
          }, _toDisplayString(_ctx.$t('extra.todo.delete-all')), 1)
        ])
      ])
    ])
  ]))
}